'use client'

import {checkIsExternal, checkIsFile, scrollToHash} from 'layout/Link'
import {PropsWithChildren, useEffect, useRef} from 'react'

import {AppRouterInstance} from 'next/dist/shared/lib/app-router-context.shared-runtime'
import {useRouter} from 'next/navigation'
import {Variant} from 'util/styler'
import {fromModule} from 'util/styler/Styler'
import css from './Wysiwyg.module.scss'

const styles = fromModule(css)

const click = (router: AppRouterInstance, e: any) => {
	if (
		e.defaultPrevented ||
		e.button !== 0 ||
		e.altKey ||
		e.metaKey ||
		e.ctrlKey ||
		e.shiftKey ||
		checkIsExternal(e.currentTarget.hostname) ||
		e.target.protocol === 'mailto:'
	)
		return

	e.preventDefault()
	const href = e.currentTarget.getAttribute('href')

	if (href.substring(0, 1) === '#') {
		scrollToHash(href)
	} else {
		router.push(href)
	}
}

const cleanWysiwyg = (text): string | null => {
	if (!text?.props?.dangerouslySetInnerHTML?.__html) return null
	const newHtml = text.props.dangerouslySetInnerHTML.__html.replace(
		/(<table[\w\W]+?\/table>)/g,
		'<div class="wysiwyg-table">$1</div>'
	)
	return newHtml
}

const removeQueryParams = (url) =>
	url.indexOf('?') > 0 ? url.substring(0, url.indexOf('?')) : url

export const WysiwygClientWrapper: React.FC<
	PropsWithChildren & {className?: string; mod?: Variant<'inherit'>}
> = ({children, className, mod}) => {
	const dom = useRef<HTMLDivElement>(null)
	const router = useRouter()

	useEffect(() => {
		const clear: (() => void)[] = []
		const listener = click.bind(router)

		const iframes: NodeListOf<HTMLIFrameElement> | undefined =
			dom?.current?.querySelectorAll('iframe')
		if (iframes?.length) {
			iframes.forEach((iframe) => {
				if (!iframe) return null
				const iframeSrc = iframe.getAttribute('src')
				if (!iframeSrc) return null

				const iframeMaps = iframeSrc?.match(/maps/)
				if (iframeMaps) {
					iframe.removeAttribute('src')
					iframe.setAttribute('data-cookieblock-src', iframeSrc)
					iframe.setAttribute('data-cookieconsent', 'statistics')
				}

				const iframeVideo = iframeSrc?.match(
					/dailymotion|powtoon|vimeo|wistia|youtube/
				)
				if (iframeVideo) {
					iframe.removeAttribute('src')
					iframe.setAttribute('data-cookieblock-src', iframeSrc)
					iframe.setAttribute('data-cookieconsent', 'marketing')
				}

				iframe.setAttribute('loading', 'lazy')
			})
		}

		const links: NodeListOf<HTMLAnchorElement> | undefined =
			dom?.current?.querySelectorAll('a')
		if (links) {
			Array.from(links).forEach((link) => {
				const href = link.getAttribute('href')
				if (!href) return
				const hrefWithoutQuery = removeQueryParams(href)

				if (checkIsFile(hrefWithoutQuery) || checkIsExternal(href)) {
					link.setAttribute('target', '_blank')
					link.setAttribute('rel', 'external nofollow noopener')
				} else {
					link.addEventListener('click', (e) => click(router, e))
					clear.push(() => link.removeEventListener('click', listener))
				}
			})
		}

		return () => {
			clear.forEach((f) => f())
		}
	})

	const cleanText = cleanWysiwyg(children)
	if (cleanText) {
		return (
			<div
				ref={dom}
				className={styles.wysiwyg.with('wysiwyg').with(className).mod(mod)()}
				dangerouslySetInnerHTML={{__html: cleanText}}
			/>
		)
	}

	return (
		<div
			ref={dom}
			className={styles.wysiwyg.with('wysiwyg').with(className).mod(mod)()}
		>
			{children}
		</div>
	)
}
