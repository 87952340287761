'use client'

import NextLink, {LinkProps as NextLinkProps} from 'next/link'
import React, {ComponentPropsWithoutRef, useMemo} from 'react'

import {usePathname} from 'next/navigation'
import {UrlObject} from 'url'

export function checkIsExternal(href: string) {
	if (href && (href.startsWith('http') || href.startsWith('https'))) return true
}

export function checkIsFile(href: string) {
	if (href && href.split('?')[0].split('/').pop()?.includes('.')) return true
}

const getHashFromUrl = (url: string): string => {
	if (!url) return ''
	if (url.startsWith('#')) return url.substring(1)
	try {
		const urlObj = new URL(
			url,
			url.substring(0, 1) === '/' ? process.env.NEXT_PUBLIC_APP_URL : undefined
		)
		if (!urlObj) return ''
		return urlObj.hash.substring(1)
	} catch (err) {
		return ''
	}
}

function checkIsInternalHash(
	href: string | undefined,
	pathName: string
): boolean {
	if (!href) return false
	if (href.startsWith('#')) return true
	try {
		const url = new URL(
			href,
			href.substring(0, 1) === '/' ? process.env.NEXT_PUBLIC_APP_URL : undefined
		)
		if (url && pathName === url.pathname && url.hash) return true
	} catch (err) {
		return false
	}

	return false
}

export type LinkProps = ComponentPropsWithoutRef<'a'> &
	Omit<NextLinkProps, 'href'> & {href?: UrlObject | string}

export const Link: React.FC<LinkProps> = ({href, ...props}) => {
	const pathName: string = usePathname() || '/'
	const isInternalHash = useMemo(
		() => checkIsInternalHash(href, pathName),
		[href, pathName]
	)
	if (!href) return <div {...(props as any)} />

	const isExternal = checkIsExternal(href)
	const isFile = checkIsFile(href)

	if (isExternal || isFile || isInternalHash) {
		return (
			<a
				{...props}
				href={href}
				onClick={(e) => {
					if (props.onClick) props.onClick(e)
					if (isInternalHash) scrollToHash(href)
				}}
				target={props.target || (isInternalHash ? undefined : '_blank')}
				rel={props.rel || (isExternal && 'external nofollow noopener')}
			/>
		)
	}

	return (
		<NextLink
			{...props}
			href={href}
			onClick={(e) => {
				if (props.onClick) props.onClick(e)
			}}
			scroll={href.includes('#') ? false : true}
		/>
	)
}

export function scrollToHash(to: string, offsetY = -25, timeout = 0) {
	if (!to) return

	const hash = getHashFromUrl(to)
	if (!hash) return

	const el: HTMLElement | null = document.getElementById(hash)
	if (!el) return
	const y = el.getBoundingClientRect().top + window.pageYOffset + offsetY
	setTimeout(() => window.scrollTo({top: y, behavior: 'smooth'}), timeout)
}
